@use "sass:meta" as ---2rhhjtudcn6;.fidelity-rules-container {
    height: 100vh;
    overflow-y: auto
}

.fidelity-rules-card{
    md-input-container{
        margin: 32px 0 0 0 !important;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:133");