@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;

body mat-sidenav-content #contentContainer {
    .context-toolbar-tabs {
        mat-tab-group > mat-tab-header div[aria-disabled="true"] {
            opacity: 1;
        }
        .mat-mdc-tab-body-wrapper {
            position: absolute;
            top: 3rem;
            left: 0;
            right: 0;
            bottom: 0rem;
        }
    }

    .context-toolbar-tabs + app-buttons-bar {
        flex: none !important;
    }

    app-left-context-toolbar,
    .context-toolbar-tabs mat-tab-header,
    app-left-context-toolbar ~ app-buttons-bar {
        border-bottom: 2px solid mat.get-color-from-palette(mat.$gray-palette, 100);
        z-index: 2;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:400");