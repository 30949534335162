@use "sass:meta" as ---2rhhjtudcn6;.historymodule-e-invoice {
    md-card {
        md-card-content {
            border-bottom: 1px solid black;
            .title {
                font-size: 24px;
                margin-bottom: 8px;
            }
            .value {
                font-size: 32px;
                margin-bottom: 8px;
            }
        }
        &.selected-card{
            background-color:#EEEEEE;
        }
    }

    .onboarding-prompt {
        height: 48px;
        background-color: #FF9800;
        color: white;
        font-weight: 500;
        padding: 0 16px;
    }

    .historymodule-list-entry-color{
        .no-margin{
            margin:unset;
            cursor:pointer;
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:100");