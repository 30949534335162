@use "sass:meta" as ---2rhhjtudcn6;.stock-movements {
    md-content.stockmovements-content {
        height: 100vh;
        overflow: hidden;
    }
    
    .stockmovements-content-list {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        .stockmovements-list-movement {
            position: relative;
            transition: background-color 0.15s linear;
            margin: 0;
            width: 100%;
            display: flex;
            border-bottom: 1px solid lightgrey;
            height: 64px;
            min-height: 64px;
            padding: 0 16px;
            div {
                overflow: hidden;
            }
            .stockmovements-list-movement-color {
                color: $text !important;
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:125");