@use "sass:meta" as ---2rhhjtudcn6;.items-showcase {
    #itemsmodules-view {
        position: relative;
        overflow: hidden !important;
    }

    .items-showcase-filter-bar {
        padding: 16px 8px;
        .items-showcase-filter-text {
            .md-errors-spacer {
                display: none;
            }
        }
        .items-showcase-price-filter {
            span {
                padding: 0 8px;
            }
        }
        md-select {
            margin: 0 0 24px 0;
        }
    }
    
    md-content.itemsmodule-products-content {
        overflow-x: hidden !important;
        .md-subheader {
            .md-subheader-inner {
                padding: 0 16px 0 8px;
            }
            .itemsmodule-showcase-subheader-content {
                div {
                    padding: 18px 0;
                }
            }
        }
    }
    
    .itemsmodule-showcase-subheader {
        background-color: $subheader-light !important;
        min-height: 48px;
    }
    
    .itemsmodule-content-list {
        padding: 0 0 8px 0 !important;
        justify-content: center;
        height: 100vh;
        overflow-y: auto;
    }
    
    .itemsmodule-showcase-no-items-placeholder {
        display: flex;
        justify-content: center;
        .itemsmodule-showcase-no-items-placeholder-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
                width: 30%;
                min-height: 0;
            }
            .itemsmodule-showcase-no-items-placeholder-message {
                color: $blue;
            }
        }
    }
    
    .itemsmodule-content-search {
        overflow-x: hidden;
        justify-content: center;
    }
    
    .itemsmodule-search-subheader {
        color: $blue-500;
        background-color: $blue-100;
        font-size: 1em;
        font-weight: 400;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 8px 0 0;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:77");