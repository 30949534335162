@use "sass:meta" as ---2rhhjtudcn6;md-content.history-entries-content {
    overflow: hidden !important;
    z-index: 1;
}

.historymodule-showcase-subheader {
    background-color: $subheader-light !important;
    min-height: 48px;
    white-space: nowrap;
}

.historymodule-content-list {
    height: 100vh;
    .historymodule-list-entry {
        position: relative;
        transition: background-color 0.15s linear;
        margin: 0;
        width: 100%;
        border-bottom: 1px solid lightgrey;
        height: 64px;
        min-height:64px;
        padding: 0 16px;
        md-checkbox {
            .md-container {
                top: 24px;
                left: 0;
                right: 6px;
                margin: 0;
            }
        }
        &.historymodule-selected-item-list{
            background: $blue-100 !important;
        }
        .historymodule-list-entry-date-color {
            color: $text-light !important;
        }
        .historymodule-list-entry-color {
            color: $text !important;
        }
    }
}

.historymodule-showcase-no-entries-placeholder{
    display: flex;
    justify-content: center;
    width: 100%;
    .historymodule-showcase-no-entries-placeholder-content{
        justify-content: center;
        align-items: center;
        img{
            width: 30%;
            min-height:0;
        }
        span {
            color: $blue;
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:101");