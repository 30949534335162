@use "sass:meta" as ---2rhhjtudcn6;.printer-upgrade-dialog {
    width: 600px;

    .printer-upgrade-dialog-label {
        margin-right: 8px;
    }
    .printer-upgrade-dialog-warning-important {
        color: red;
        font-weight: bold;
        margin-bottom: 16px;
    }
    .printer-upgrade-dialog-up-to-date {
        font-weight: bold;
        color: lightgreen;
    }
    .printer-upgrade-dialog-status-container {
        margin-top: 24px;
    }
    .printer-upgrade-dialog-update-available {
        margin-bottom: 24px;
    }
    .printer-upgrade-dialog-error-message {
        font-weight: bold;
        text-align: center;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:62");