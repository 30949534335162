@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $text: mat.get-color-from-palette($primary-palette,'100-contrast');
    $is-dark-theme: map.get($color-config, 'dark-theme');

    //@debug 'SEARCH_BAR' $color-config;

    app-search-bar{
        mat-form-field.frame-search-bar {

            //padding: 0 1rem 0;
            //border-radius: 10px;
            color: $text !important;

            .mat-mdc-form-field-flex {
                background-color: mat.get-color-from-palette($primary-palette) !important;
                align-items: center;
            }

            .mat-mdc-form-field-infix {
                border-top: 0;
            }

            .mat-mdc-form-field-subscript-wrapper {
                display: none;
            }

            [matformfieldfloatinglabel] {
                top: 50% !important;
            }

            &.focus,
            &:hover
            {
                background-color: mat.get-color-from-palette($primary-palette, 'lighter') !important;

                &, input, input::placeholder {
                    color: mat.get-color-from-palette($primary-palette, '50-contrast'), !important;
                    caret-color: mat.get-color-from-palette($primary-palette, '50-contrast') !important;
                    input::placeholder {
                        opacity: 0.5;
                    }
                }

                .mat-mdc-form-field-flex {
                    background-color: mat.get-color-from-palette($primary-palette, 'lighter') !important;
                }

            }
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:375");