@use "sass:meta" as ---2rhhjtudcn6;.printersmodule-modal-content {
    padding: 32px 24px 32px 24px !important;
    .label{
        margin-left: 0px;
        padding-bottom: 16px;
    }
    .md-label{
        margin-left: 36px;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:59");