@use "sass:meta" as ---2rhhjtudcn6;/* Component SCSS */
@import "service/dialog/standard-dialog-template.scss";

@import "component/sidenav/sidenav.scss";
@import "component/keypad-pin/keypad-pin.scss";
@import "component/showcase-photogrid/showcase-photogrid.scss";
@import "component/showcase-grid/showcase-grid.scss";
@import "component/showcase-list/showcase-list.scss";
@import "component/update-required/update-required.scss";
@import "service/dialog/offline-dialog/offline-dialog.scss";
@import "service/dialog/add-select-customer/add-select-customer.scss";
@import "service/dialog/item-selector/item-selector.scss";
@import "service/dialog/prompt-dialog/prompt-dialog.scss";
@import "service/dialog/radio-list-selector/radio-list-selector.scss";
@import "service/toast/toast.scss";
@import "directive/loader/loader.scss";
@import "directive/barcode/barcode.scss";
@import "directive/pagination-navigator/pagination-navigator.scss";
@import "directive/image-handler/image-handler.scss";
@import "service/dialog/online-only-dialog/online-only-dialog.scss";
@import "service/dialog/printer-error-orders/printer-error-orders.scss";
@import "service/dialog/general-document-printer-select/general-document-printer-select.scss";
@import "directive/barcode-scanner/barcode-scanner.scss";
@import "service/dialog/demo-greeting-dialog/demo-greeting-dialog.scss";
@import "service/dialog/icon-list-dialog/icon-list-dialog.scss";
@import "service/dialog/device-info-dialog/device-info-dialog.scss";
@import "service/dialog/number-input-dialog/number-input-dialog.scss";
@import "service/dialog/item-list-selector/item-list-selector.scss";
/* Add Component SCSS Above */

md-menu-content {
    max-height: 90vh;
}

.md-flat-rounded {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.lock-actions {
    pointer-events: none;
}
.no-padding {
    padding: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

//menu dropdown items classes fixed during development of new menu features by angular material
.md-button-menu-selected {
    background-color: rgba(158,158,158,0.25) !important;
}

.md-button-menu-disabled {
    cursor:not-allowed;
    opacity: 0.56;
}

md-menu-content{
    overflow-x: hidden;
}

span.offline-icon{
    display: block;
    float: left;
    margin-left: 16px;
    md-icon{
        color:  $offline-icon !important;
        fill:   $offline-icon !important;
    }
}

.notification-badge {
    height: 24px;
    width: 32px;
    border-radius: 30%;
    color: white;
    text-align:  center;
    font-size:  16px;
    border: 0;
    vertical-align: middle;
    font-weight: bold;
    line-height: 24px;
}

.narrow-buttons-on-mobile {
    @media screen and (max-width: 799px) {
        button {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
}

.stock-status {
    margin-left: 8px;
    &.stock-disabled {
        display: none;
    }
    &.stock-info-available {
        background-color: $available-stock-color;
    }
    &.stock-info-alert {
        background-color: $alert-stock-color;
    }
    &.stock-info-unavailable {
        background-color: $unavailable-stock-color;
    }
    &.stock-info-nostock {
        background-color: lightgrey;
    }
}

.item-stock-status-with-quantity {
    @extend .stock-status;
    border-radius: 40%;
    padding-left: 7px;
    padding-right: 7px;
}

.item-stock-status {
    @extend .stock-status;
    height: 16px;
    width: 16px;
    border-radius: 100%;
}

.showcase-list-item {
    :active {
        opacity: 0.75;
    }
    position: relative;
    border-bottom: 1px solid lightgrey;
    height: 64px;
    min-height: 64px;

    .showcase-list-item-image {
        position: relative;
        max-width: 64px;
        width: 64px;
        height: 64px;
        max-height: 64px;
        object-fit: cover;
    }
    .showcase-list-item-badge {
        margin-left: 5px;
        margin-right: 5px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: $blue;
    }
    .showcase-list-item-name {
        text-align: left;
        margin-left: 8px;
        font-size: 1em;
        color: $text-title;
        font-weight: 500;
        overflow: hidden;
        max-height: 64px;
        line-height: 16px;
        & md-chips {
            font-size: 14px;
            padding: 0;
        }
    }
    .showcase-list-item-price {
        flex: 0 17%;
        float: right;
        width: 100%;
        color: $blue;
        display: flex;
        margin-right: 5px;
        text-align: right;
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        justify-content: flex-end;
    }
    .showcase-list-item-wrapper-badge-exits {
        text-align: left;
        .showcase-list-item-badge-exit {
            background-color: $blue;
            color: white;
            margin: 2px 2px 0 0;
            height: 20px;
            line-height: 20px;
        }
    }
}

.vat-found {
	background-color: #9CCC65 !important;
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:9");