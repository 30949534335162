@use "sass:meta" as ---2rhhjtudcn6;.kiosk-cart {
    .wrapper-background {
        position: relative;
        video.background {
            width: 100%;
            object-fit: cover;
        }
        img.background {
            height: 100%;
            width: 100%;
        }
        .list-order {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0;
            .list-order-item {
                background-color: rgba($color: #ffffff, $alpha: 1);
                min-height: 70px;
                border-bottom: 1px solid #cecece;
                -webkit-transition-property: all;
                -moz-transition-property: all;
                transition-property: all;
                -webkit-transition-duration: .1s;
                -moz-transition-duration: .1s;
                transition-duration: .1s;
                -webkit-transition-timing-function: step-start;
                -moz-transition-timing-function: step-start;
                transition-timing-function: step-start;
                font-weight: bold;
                .wrapper-btn {
                    display: -webkit-flex;
                    display: -moz-box;
                    display: flex;
                    width: 25%;
                    margin: 0;
                    border: 1px solid #ced1d0;
                    border-radius: 25px;
                    background-color: white;
                    vertical-align: middle;
                    .minus, .add { width: 30%; }
                    .minus { text-align: left; }
                    .add { text-align: right; }
                    .btn { margin: 0; }
                    .count {
                        width: 40%;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
                .wrapper-thumbnail {
                    width: 25%;
                    text-align: center;
                    .thumbnail {
                        height: 60px;
                    }
                }
                .item-no-stock {
                    color: #800;
                }
                .kiosk-cart-item-notes {
                    padding-left: 16px;
                    white-space: pre-wrap;
                    font-size: 0.8em;
                }
                .kiosk-cart-item-amount {
                    margin-right: 4px;
                }
            }
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:141");