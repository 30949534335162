@use "sass:meta" as ---2rhhjtudcn6;
mat-hint {
    font-size: x-small;
    letter-spacing: 0.3px;
}

mat-form-field {
    .mat-mdc-text-field-wrapper {
        border-radius: 5px;
        .mdc-line-ripple {
            &:before {
                border-bottom-style: none;
            }
        }
    }
}

mat-form-field.no-space-bottom .mat-mdc-form-field-subscript-wrapper {
    display: none;
}

button[mat-icon-button].frame-button {
    .mat-mdc-button-persistent-ripple.mdc-icon-button__ripple {
        display: none;
    }
}

button[mat-button].mat-button-sidebar.full-size {
    .mdc-button__label {
        width: 100%;
    }
}

.mat-dialog-content {
    margin: 0 !important;
    padding: 24px 32px !important;
}

.support-dialog-container .mat-dialog-container {
    width: 49rem;
    //height: 27rem;
    padding: 0 !important;
}

.no-padding-dialog-container .mat-dialog-container {
    padding: 0 !important;
    @media (max-width: 400px) {
        pre, .title {
            scale: 0.8;
        }
    }

}

.input-container-search .mat-form-field-wrapper .mat-form-field-flex {
    background: transparent;
}

.mat-mdc-tab-body-wrapper {
    flex-grow: 1;
}

button.mat-mdc-fab, .mat-mdc-mini-fab {
    &,
    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }
}

mat-card.mat-mdc-card {
    box-shadow: none;
}

mat-tab-group > mat-tab-header > div.mat-mdc-tab-label-container{
    @media only screen and (max-width: 574px) {
        width: 0.50vw;
    }
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.rotate-infinite {
    animation: rotate infinite 2s linear;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    width: 100%;
    padding: 0 !important;
}

.order-printer-select-dialog-list mat-list.mat-mdc-list-base {
    @media only screen and (max-width: 574px) {
        height: calc(82vh) !important;
    }
    height: calc(46vh) !important;
    overflow-y: auto;
}

#input-lottery-code:not(.mat-focused) mat-label {
    @media only screen and (max-width: 475px) {
        display: inline-block;
        width: calc(100vw - 110px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:396");