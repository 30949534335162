@use "sass:meta" as ---2rhhjtudcn6;.add-select-customer {
    @media screen and (min-width: 640px) {
        width: 550px;
    }

    .add-select-customer-header {
        background-color: $primary-tilby;
        z-index: 2;
        flex-shrink: 0;
        padding: 0 16px 16px 16px;
        .add-customer-top-subheader{
            height: 56px;
        }
        md-icon {
            color: black;
        }
    }
    
    .add-select-customer-shippingaddress-select {
        font-size: 0.9em;
        margin-bottom: 0;
    }
    
    .add-select-customer-list {
        width: 100%;
        height: 100vh;
        min-height: 160px;
    }

    .add-select-customer-quick-add {
        background-color: $blue-500;
        border-radius: 100%;
        margin-right: 8px;
        height: 40px;
        width: 40px;
        md-icon {
            display: block;
            height: 40px;
        }
    }

    .add-select-customer-dialog-content {
        padding: 0;
        .md-virtual-repeat-offsetter {
            padding: 24px 32px;
        }
    }
    
    .add-customer-info{
        text-overflow: ellipsis;
        overflow-x: hidden;
        margin-left: 16px;
    }

    .add-select-customer-fidelity-info {
        font-size: 11px;
    }
    
    .add-select-customer-no-customer{
            margin-left: 16px;
    }
    
    .add-select-customer-row-list{
        align-items: center;
        padding-bottom: 16px;
        margin-left: 0;
        color: $text;
        .add-select-customer-fidelity{
            margin-right: 32px;
        }
    }
    
    .add-select-customer-search-input{
        margin: 0;
        .md-errors-spacer {
            display: none;
        }
    }
    
    .add-select-customer-md-icon {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: white;
        margin-left: 16px;
        margin-right: 8px;
        flex:0 40px;
    }
    
    .add-select-customer-subtitle{
        font-size: .9em;
        color: $text-title
    }
    
    .customer-modal-search-placeholder-content{
        padding: 24px 32px;
        text-align: center;
        div {
            color: $blue;
        }
    }
    
    .add-select-customer-avatar{
        border-radius: 50%;
        height: 40px;
        width: 40px;
        background-color: white;
        margin-left: 0px;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:23");