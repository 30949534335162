@use "sass:meta" as ---2rhhjtudcn6;// CASHREGISTER
app-cashregister, // TAG-ANGULAR DA METTERE SEMPRE ( PER PASSAGGIO A ANGULAR ROUTER)
cashregister-new-component,  // TAG DOWNGRADATO, FINCHE USIAMO UI_ROUTER, IL COMPONENTE È DOWNGRADATO (in ajs-downgrade.ts)
//ALTRO MODULO
{
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:0");