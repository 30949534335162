@use "sass:meta" as ---2rhhjtudcn6;.category-tab {
    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
        color: lightgray !important;
    }

    .mdc-tab-indicator--active .mdc-tab-indicator {
        background-color: black !important;
        transition: background-color 0.3s ease;
    }

    .mat-mdc-tab-header:not(.mat-mdc-tab-header-pagination-controls-enabled) {
        .mdc-tab:first-of-type .mdc-tab-indicator {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
        }

        .mdc-tab:last-of-type .mdc-tab-indicator {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
        }
    }

    .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
        display: none !important;
    }

    .mat-mdc-tab-header-pagination-before,
    .mat-mdc-tab-header-rtl .mat-mdc-tab-header-pagination-after {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        border-left: 1px solid lightgrey !important;
        border-top: 1px solid lightgrey !important;
        border-bottom: 1px solid lightgrey !important;
    }

    .mat-mdc-tab-header-rtl .mat-mdc-tab-header-pagination-before,
    .mat-mdc-tab-header-pagination-after {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border-right: 1px solid lightgrey !important;
        border-top: 1px solid lightgrey !important;
        border-bottom: 1px solid lightgrey !important;
    }

    .mdc-tab {
        border-top: 1px solid lightgrey !important;
        border-bottom: 1px solid lightgrey !important;
        border-left: 1px solid lightgrey !important;
    }

    .mat-mdc-tab-header.mat-mdc-tab-header-pagination-controls-enabled {
        .mdc-tab:last-of-type {
            border-right: 1px solid lightgrey !important;
        }
    }

    .mat-mdc-tab-header:not(.mat-mdc-tab-header-pagination-controls-enabled) {
        .mdc-tab:first-of-type {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            border-left: 1px solid lightgray !important;
            border-top: 1px solid lightgray !important;
            border-bottom: 1px solid lightgray !important;
        }

        .mdc-tab:last-of-type {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
            border-right: 1px solid lightgray !important;
            border-top: 1px solid lightgray !important;
            border-bottom: 1px solid lightgray !important;
        }
    }
  }

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:401");