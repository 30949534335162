@use 'sass:map' as map;
@use '@angular/material' as mat;
@use 'src/app/core/components/new-material-sidenav/new-material-sidenav-theme.component' as sidenav;
@use 'src/app/core/components/generalized-toolbar/search-bar/search-bar-theme.component' as search_bar;
@use 'src/styles.global/components/ag-grid' as ag-grid;
@use './generic-themes/tilby-body-generalized-components-theme.component' as tilby-body-components;
@use './generic-themes/tilby-frame-generalized-components-theme.component' as tilby-frame-components;
@use 'node_modules/@tilby/tilby-ui-lib/styles.global' as tilby;
@use 'node_modules/@tilby/tilby-ui-lib/styles.global/components/themes' as tilby-lib-components;
@import "tilby-palette";
@import "components/index";

$material-design-icons-font-path: '~@material-design-icons/font/';
@import 'node_modules/@material-design-icons/font/index';
$material-symbols-font-path: '~material-symbols/';
@import 'node_modules/material-symbols/index';

@include mat.core();

$primary: mat.define-palette(tilby.$tilby-palette-primary);
$accent: mat.define-palette(tilby.$tilby-palette-accent);
$warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme((color:(primary:$primary, accent:$accent, warn:$warn)));
@include mat.all-component-themes($theme);
@include ag-grid.color($theme);
@include tilby-body-components.color($theme);
@include tilby-lib-components.color($theme);

.the-dense-zone {
    @include mat.all-component-densities(-2);

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
        display: block !important;
    }

    &.min-height {
        .mat-mdc-form-field-infix {
            padding: 0 !important;
            min-height: 0 !important;
        }
    }
}

$frame-primary: mat.define-palette(tilby.$tilby-frame-palette-primary);
$frame-accent: mat.define-palette(tilby.$tilby-frame-palette-accent);
$frame-warn: mat.define-palette(mat.$red-palette);
$frame-theme: mat.define-light-theme((color:(primary:$frame-primary, accent:$frame-accent, warn:$frame-warn)));
@include sidenav.color($frame-theme);
@include tilby-frame-components.color($frame-theme);

$search-primary: mat.define-palette(tilby.$tilby-search-palette-primary);
$search-accent: mat.define-palette(tilby.$tilby-search-palette-accent);
$search-warn: mat.define-palette(mat.$red-palette);
$search-theme: mat.define-light-theme((color:(primary:$search-primary, accent:$search-accent, warn:$search-warn), density: -1));
@include search_bar.color($search-theme);
app-left-context-toolbar,
customers-details mat-tab-header,
app-left-context-toolbar ~ app-buttons-bar
{
    border-bottom: 2px solid mat.get-color-from-palette(mat.$gray-palette,100);
    z-index: 2;
}

app-customer-fidelity-points-form,
app-customer-prepaid-credit-form {
    .mat-toolbar {
        background-color: #fff !important;
    }
}
.dark-theme {
    $dark-primary: mat.define-palette(tilby.$tilby-palette-primary-dark);
    $dark-accent: mat.define-palette(tilby.$tilby-palette-accent-dark);
    $dark-warn: mat.define-palette(mat.$red-palette);
    $dark-theme: mat.define-dark-theme((color: (primary: $dark-primary, accent: $dark-accent, warn: $dark-warn)));
    //@include mat.core-theme($dark-theme);
    @include mat.all-component-colors($dark-theme);
    @include ag-grid.color($dark-theme);
    @include tilby-body-components.color($dark-theme);
    @include tilby-lib-components.color($dark-theme);

    $frame-dark-primary: mat.define-palette(tilby.$tilby-frame-palette-primary-dark);
    $frame-dark-accent: mat.define-palette(tilby.$tilby-frame-palette-accent-dark);
    $frame-dark-warn: mat.define-palette(mat.$red-palette);
    $frame-dark-theme: mat.define-dark-theme((color: (primary: $frame-dark-primary, accent: $frame-dark-accent, warn: $frame-dark-warn)));
    @include sidenav.color($frame-dark-theme);
    @include tilby-frame-components.color($frame-dark-theme);

    $search-dark-primary: mat.define-palette(tilby.$tilby-search-palette-primary-dark);
    $search-dark-accent: mat.define-palette(tilby.$tilby-search-palette-accent-dark);
    $search-dark-warn: mat.define-palette(mat.$red-palette);
    $search-dark-theme: mat.define-light-theme((color:(primary:$search-dark-primary, accent:$search-dark-accent, warn:$search-dark-warn)));
    @include search_bar.color($search-dark-theme);

    app-left-context-toolbar,
    customers-details mat-tab-header,
    app-left-context-toolbar ~ app-buttons-bar
    {
        border-bottom: 2px solid mat.get-color-from-palette(mat.$gray-palette,50);
        z-index: 2;
    }

    app-customer-fidelity-points-form,
    app-customer-prepaid-credit-form {
        .mat-toolbar {
            background-color: #4a4a4a !important;
        }
    }

    .dashboard-bg {
        background-color: mat.get-color-from-palette($tilby-palette-dark, 100);
    }

    .sale-header-info-button-bg {
        background-color: mat.get-color-from-palette($tilby-palette-dark, 400) !important;
    }

    .pay-button:hover {
        background-color: mat.get-color-from-palette($tilby-palette-dark, 400) !important;
    }


    .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
        border-color: #ffffff;
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
        color: #ffffff;
    }

    .dark-button-green {
        background-color: #b5f0b5 !important;
    }

    .avatar-button-text {
        color:black !important;
    }

    new-sidenav-menu-component {
        background: mat.get-color-from-palette(tilby.$tilby-frame-palette-accent-dark, 700);
    }

    .category-tab {
        .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
            color: white !important;
            // border-top-color: blue !important;
            // border-bottom-color: blue !important;
        }

        .mdc-tab__text-label {
            color: white !important;
        }

        .mdc-tab-indicator--active .mdc-tab-indicator {
            background-color: #1a73e8 !important;
            color: white !important;
            transition: background-color 0.3s ease;
        }
    }

    app-sidenav-menu app-nav-list .nav-button-active {
        background-color: #1a73e8 !important;
    }

    app-tilby-draggable-bottom-sheet {
        .draggable-bottom-sheet-bg {
            background-color: #212121 !important;
        }
    }

    app-cashregister-keypad > button{
        background-color: #424242 !important;
    }

    app-tilby-sale-keypad tilby-general-input-button {
        background-color: #cad9e7 !important;
        button {
            color: #424242 !important;
        }
    }

    app-cashregister-keyboard {
        background-color: #212121 !important;
    }

    app-cashregister-keyboard {
        .sale-keypad-button__dark {
            background-color: #cad9e7 !important;
                button {
                    color: #424242 !important;
                }
        }
    }

    app-category-grid {
        .category-grid__selected {
            background-color: #1a73e8;
            color: white;
        }
    }

    app-showcase-grid {
        color: black !important;
    }

    cashregister-payments-component {
        .button-check{
            color: black !important;
        }
        .button-selected {
            background: #1a73e8 !important;
            color: white !important;
        }
        .button-check:active {
            background: #1a73e8 !important;
            color: white !important;
        }
    }

    app-tickets-values-sorted {
        .button-check{
            color: black !important;
        }
        .button-check:active {
            background: #1a73e8 !important;
            color: white !important;
        }
    }

    .payments-keypad-cover {
        background: black !important;
        padding: 8px !important;
    }

    .menu-label-icon-color {
        color: black !important;
    }

}

.dashboard-bg {
    background-color: mat.get-color-from-palette($tilby_frame-palette, 100);
}

.avatar-button-text {
    color:white !important;
}

.sale-header-info-button-bg {
    background-color: #f5f5f5 !important;
}

.pay-button:hover {
    background-color: #f7fafc !important;
}

app-cashregister-keypad > button {
    background-color: #f3f4f6 !important;
}

app-tilby-sale-keypad tilby-general-input-button {
    background-color: #a1a1aa !important;
}

app-cashregister-keyboard {
    tilby-general-input-button {
        background-color: #a1a1aa !important;
    }
}

app-category-grid, new-category-grid {
    .category-grid__selected {
        background-color: black;
        color: white;
    }
}

cashregister-payments-component {
    .button-check {
        color: black !important;
    }
    .button-selected {
        background: black !important;
        color: white !important;
    }
    .button-check:active {
        background: black !important;
        color: white !important;
    }
}

app-tickets-values-sorted {
    .button-check{
        color: black !important;
    }
    .button-check:active {
        background: black !important;
        color: white !important;
    }
}

.menu-label-icon-color {
    color: white !important;
}


//Modals Rounded Corners
// XSmall	(max-width: 599.98px) from documentation of Angular material https://material.angular.io/cdk/layout/overview
@media screen and (min-width: 599.98px) {
body > .cdk-overlay-container {
        .mat-mdc-dialog-surface {
            border-radius: 1.5rem !important;
        }
    }
}
// QR-Code in Opzioni invio ricevuta (receipt-options-dialog)
.tw-rounded-3xl canvas{
    border-radius: 1.5rem !important;
}

//New Modals scroll strategy create a scrollbar useless in AngularJs
html.cdk-global-scrollblock{
    overflow-y: auto;
}

.mat-pointer-events-none{
    *[class^="mat-"]{
        *{
            pointer-events: none !important;
        }
    }
}
