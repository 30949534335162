@import "./tag-reset";

$blue: rgb(33,150,243); // blue

// FIX FOR MAT-DATE-PICKER AND ALL OTHERS SIMILAR COMPONENTS
mat-form-field:has(input[type="time"], input[type="datetime-local"], input[matDatepicker], input.mat-datepicker-input, mat-date-range-input[rangePicker]) {
    & *:not(button,mat-datepicker-toggle,div.mat-mdc-form-field-icon-suffix) {
        pointer-events: none !important;
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

 // FIX FOR SCROLL PERFORMANCE MOBILE WITH OVERLAY IN ABSOLUTE POSITION AND USELESS FULL_WIDTH
body:has(ag-grid-angular) {
    &>div.cdk-overlay-container:has(>:only-child):has(app-grid-save-user-preference),
    &>div#content .ag-full-width-container[name="fullWidth"] {
        width: fit-content;
        width: -moz-fit-content;
    }
}

.mat-snack-bar-tilby {
    & .mdc-snackbar__surface {
        min-width: 100% !important;
    }
    --mat-mdc-snack-bar-button-color: #448AFF;
}

body.grid-columns-movable {
    .header-with-icon::before {
        content: "\e89f";
        font-family: "Material Symbols Outlined", serif;
        margin-right: 5px;
        font-size: 1.5rem;
    }
}

// fix - angular-split version 16
as-split > as-split-area.as-split-area > div.iframe-fix{
    width: fit-content !important;
    height: fit-content !important;
}

.as-split-gutter-icon{
    font-family: 'Material Icons';
    background-image: none !important;
    font-size: 1.5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    &:before{
        display: block;
        content: 'more_horiz';
    }
}

