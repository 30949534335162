@use "sass:meta" as ---2rhhjtudcn6;.offline-img-container{
    width:100%;
    clear: both;
    text-align:center;
}

.offline-modal-text{
    margin-top: 30px;
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:22");