@use "sass:meta" as ---2rhhjtudcn6;.printers-printer-row {
    height: 52px;
    min-height: 52px;
    &.printers-printer-selected {
        background-color: $blue-100;
    }
    .printers-printer-icon {
        flex: 0 52px;
        text-align: center;
    }
    .printers-printer-name {
        color: $blue;
    }
    .printers-printer-info {
        color: $text;
        font-size: .7em;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:52");