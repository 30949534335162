@use "sass:meta" as ---2rhhjtudcn6;.combinations-radio{
    width: 100%;
    //padding-left: 32px;
    //padding-top: 24px;
    md-radio-button{
        margin-bottom: 32px;
    }
    .md-label{
        width:100%;
        color:$text;
        padding-right: 12px;
        .combinations-radio-flex{
            display: flex;
            align-items: center;
            width: 100%;
            .combination-name{
                margin-left: 16px;
            }
        }
    }
    .stock-info{
        display: flex;
        align-items: center;
        .stock-info-rounded{
            height: 24px;
            width:24px;
            border-radius: 50%;
            flex: 0 24px;
            &.stock-info-available{
                background-color: $available-stock-color;
            }
            &.stock-info-alert {
                background-color: $alert-stock-color;
            }
            &.stock-info-unavailable {
                background-color: $unavailable-stock-color;
            }
            &.stock-info-nostock {
                height: 0px;
            }
        }

        .stock-info-text{
            color: $text-title;
            margin-left: 8px;
        }
    }

}

.combinations-modal-content{
    padding: 0 !important;
}

.combinations-table-header{
    border-bottom: 1px solid lightgrey;
    height: 56px;
}

.combinations-table{
    padding: 24px 32px 0 32px;
}

.combinations-table-header-cell{
    color:$text;
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:66");