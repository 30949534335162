@use "sass:meta" as ---2rhhjtudcn6;md-dialog-content.logo-editor-content {
	padding:0 !important;
	.md-input[disabled] {
        background-image: none !important;
        color: black;
    }
}

.logo-editor-modal-width {
	min-width:60%;
}

.crop-area {
    overflow: hidden;
    height:350px;
}

.drop-zone {
    cursor:pointer;
    overflow:hidden;
    display:block;
    position:relative;
    width:100%; 
    height:300px;
    margin: 18px 0;
    background:whitesmoke; 
    border-radius:4px;
    font:caption; 
    text-align:center; 
    border:1px dotted silver;
    box-sizing:border-box;
    
    // Transcluded content 
    p { padding-top:16px; font-size:16px; } 
    span { display:block; font-size:10px;}    
    img  { position:absolute; top:0; left:0; height:100px;}
    // File input 
    input{
        position:absolute;
        top:0;right:0;bottom:0;left:0;
        font-size:9999px;  // <= Lazy dog – cover it all
        opacity:0;
        cursor:pointer;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:113");