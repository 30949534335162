@use "sass:meta" as ---2rhhjtudcn6;.kiosk-confirmation {
    video {
        height: 100%;
        width: auto;
    }
    img.background {
        overflow: hidden;
        height: 100%;
        width: auto;
    }
    @media (orientation: landscape) {
        video {
            height: auto;
            width: 100%;
            object-fit: fill;
        }
        img.background {
            height: auto;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:146");