@use "sass:meta" as ---2rhhjtudcn6;.fidelity-campaigns-left-column {
    border-right: 1px solid lightgrey;
    position: relative;
    .fidelity-campaigns-container {
        height: 100vh;
        padding-top: 16px;
        padding-bottom: 16px;
        overflow-y: auto;
        .fidelity-campaign-details {
            min-height: 52px;
            height: 52px;
            &.fidelity-campaign-selected {
                background-color: $blue-100;
            }
            .fidelity-campaign-details-icon {
                flex: 0 52px;
                text-align: center;
            }
            .fidelity-campaign-name {
                color: $blue;
            }
            .fidelity-campaign-small-date {
                color: $text;
                font-size: .7em;
            }
        }
    }
}

.fidelity-campaigns-right-column {
    .fidelity-campaigns-save {
        background: $blue-100;
        padding-left: 24px;
        min-height: 48px;
        max-height: 48px;
        align-items: center;
        h3 {
            color: $blue-500;
            font-weight: 400;
        }
    }
    .fidelity-campaign-form-container {
        height: 100vh;
        overflow-y: auto;
        h4 {
            color: $blue !important;
            font-weight: 500;
            padding: 0 16px;
            text-transform: uppercase;
        }
    }
    .fidelity-campaign-switch{
        margin-top: -16px;
    }   
    .fidelity-campaign-card{
        margin:0 !important;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:135");