@use "sass:meta" as ---2rhhjtudcn6;.activation-request-pos-dialog {
    p.title {
        max-width: 450px;
        margin-top: 0;
        text-align: left;
    }
    .error {
        color: #800;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:118");