@use "sass:meta" as ---2rhhjtudcn6;.kiosk-header {
    position: relative;
    .kiosk-header-banner {
        position: relative;
        video.banner {
            width: 100%;
            object-fit: cover;
        }
        img.banner {
            width: 100%;
            object-fit: cover;
        }
        .wrapper-logo {
            position: absolute;
            top: 0;
            left: 5%;
            height: 100%;
            align-items: center;
            z-index: 10;
            .logo {
                max-height: 50%;
            }
        }
    }
    .kiosk-header-title-row {
        align-items: center;
        align-content: center;
        padding: 0 16px;
        margin: 16px 0;
        .kiosk-header-title {
            margin: 0;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:137");