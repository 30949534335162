@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;

app-search-bar {
    mat-form-field,
    mat-form-field > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex,
    {
        border-radius: 10px;
    }

    mat-form-field {
        div[matformfieldnotchedoutline] {
            display: none;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:399");