@use "sass:meta" as ---2rhhjtudcn6;.e-commerce-settings {
    .itemsmodule-images {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.itemsmodule-image-container{
			position: relative;
			margin: 16px 32px 16px 0;
			.itemsmodule-remove-image{
				position: absolute;
				top: -10px;
				right: -10px;
				width: 20px;
				height: 20px;
				text-align: center;
				border-radius: 100%;
				background: white;
			}
		}
	}

    .list-featured-products {
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 240px;

        width: 100%;
        .featured-product {
            padding: 10px;
            margin: 5px;
            border: 1px solid gray;
            height: 100%;
            text-align: center;
            background-color: white;
            .thumbnail {
                height: 90px;
            }
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:121");