@use "sass:meta" as ---2rhhjtudcn6;.pagination{
	min-height: 48px;
	background-color: #F5F5F5;
	color: #757575;
	text-align:center;
	overflow-y: hidden;
	button{
		min-width: 48px;
	}

	.open-page-menu{
		margin:auto 5px;
		cursor:pointer;
	}
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:30");