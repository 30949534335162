@use "sass:meta" as ---2rhhjtudcn6;.promotion-details {
    md-content.promotion-details-content {
        height: 100vh;
        z-index: 1;
        overflow-x: hidden !important;
        .md-input[disabled] {
            background-image: none !important;
            color: black;
        }
    }
    
    .promotions-details-items-title {
        background-color: $subheader-light !important;
        min-height: 48px;
        padding-left: 0;
        padding-right: 64px;
        font-weight: bold;
        color: #666666;
    }
    
    .promotion-details-item-row {
        border-bottom: 1px solid lightgrey;
        min-height: 48px;
        line-height: 24px;
        span {
            overflow: hidden;
            padding: 0 2px;
            word-wrap: break-word;
        }
        input {
            width: 96px;
            text-align: right;
        }
        .promotion-details-item-price{
            span {
                padding: 0 8px;
            }
        }
    }

    .promotion-items-search-box {
        md-input-container {
            margin: 0;
        }
        md-icon {
            top: -4px;
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:152");