@use "sass:meta" as ---2rhhjtudcn6;.demo-greeting-dialog {
    width: 700px;
    .demo-greeting-dialog-avatar {
        border-radius: 50%;
        min-width: 128px;
        max-width: 128px;
        max-height: 128px;
        margin-right: 24px;
    }

    .demo-greeting-dialog-paragraph {
        margin-bottom: 1em;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:39");