@use "sass:meta" as ---2rhhjtudcn6;showcase-grid {
    .showcase-grid-tile-no-thumbnail {
        :active {
            opacity: 0.75;
        }
        width: 100%;
        height: 96px;
        overflow: hidden;
        figure {
            display: flex;
            flex-direction: column;
        }
        .item-tile-name {
            display: flex;
            overflow: hidden;
            color: $text-title;
            font-weight: 500;
            text-align: center;
            padding: 2px;
        }
        .item-footer {
            background: rgba(255, 255, 255, 0.70);
            height: 32px;
            width: 100%;
            .item-price {
                color: $blue;
                text-align: right;
                padding-right: 8px;
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:19");