@use "sass:meta" as ---2rhhjtudcn6;.kiosk-customer-selection {
    .kiosk-customer-row {
        position: relative;
        overflow: hidden;
        .kiosk-wrapper-btn-help {
            position: absolute;
            top: 0;
            right: 0;
            margin: 16px 16px 0 0;
        }
        video.background {
            width: 100%;
            object-fit: cover;
        }
        img.background {
            height: 100%;
            width: 100%;
        }
        .kiosk-wrapper-title {
            position: absolute;
            h1, .kiosk-sub-title {
                max-width: 90%;
                text-align: center;
            }
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:144");