@use "sass:meta" as ---2rhhjtudcn6;.loader-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex: 1;
    .loader-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        @media screen and (max-width: 400px) {
            width: 85%;
        }
    }
    .loader-logo {
        margin: 0px auto 16px auto;
        max-width: 100%;
        width: 372px;
    }
    .loader-caption {
        margin-top: 36px;
        font-size: 1.5em;
        color: #666666;
        white-space: pre-wrap;
        &.loader-caption-main {
            font-size: 1.5em;
            margin-top: 16px
        }
    }
    .logo-zucchetti {
        position: absolute;
        bottom: 25px;
        width: 25%;
        text-align: center;
        img {
            max-width: 50%;
        }
        @media screen and (max-width: 700px) {
            width: 85%;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:28");