@use "sass:meta" as ---2rhhjtudcn6;.lean-pms-payments {
    height: 80%;
    min-height: 300px;
    @media screen and (max-width: 639px) {
        max-width: 100vw;
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
    }
    .lean-pms-payments-payment {
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: row;
        align-items: center;
        .lean-pms-payments-icon {
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }
        .lean-pms-payments-divider {
            margin: 0 8px;
        }
        .lean-pms-payments-amount {
            font-weight: 500;
        }
        &.selected {
            background-color: $blue-100;
        }
    }
    .lean-pms-payments-search {
        input {
            border: 0;
            border-bottom: 2px solid $blue-500;
            background-color: transparent;
        }
    }
    .lean-pms-payments-message-box {
        min-height: 48px;
        background-color: $primary-tilby;
    }
    .lean-pms-no-account {
        color: $blue;
    }
    .lean-pms-payments-room {
        font-weight: bold;
        font-size: 36px;
    }
    .lean-pms-room-service-container {
        border: 1px solid lightgray;
        .lean-pms-room-service-header {
            border-bottom: 1px solid lightgray;
            text-align: center;
            font-weight: bold;
        }
    }
    .bg-gray {
        background-color: #eee;
    }
    .note {
        font-size: 12px;
        font-weight: bold;
        padding-left: 5px;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:73");