@use "sass:meta" as ---2rhhjtudcn6;.printers-light-text{
    color: $text;
}

.printers-lighter-text{
    color: #BDBDBD;
}

.printers-text{
    color: $text-title;
    word-wrap: break-word;
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:54");