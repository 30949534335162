@use "sass:meta" as ---2rhhjtudcn6;.general-settings {
    .settingsmodule-form {
        md-card {
            .pricelist-container {
                md-switch {
                    padding: 0 8px;
                }
            }
            md-switch {
                .md-label {
                    max-width: 85%;
                    white-space: normal;
                    line-height: 15px;
                }
            }
        }
    }

    .settings-section-icon {
        padding-right: 1em;
        md-icon {
            color: black;
        }
    }

    .settings-input-container-no-error {
        .md-errors-spacer {
            display: none;
        }
    }

    .payment-method-card {
        margin-bottom: 16px;
        > .header {
            padding: 12px 8px;
            > .badge {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                background-color: $blue-500;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                &.new-payment {
                    opacity: 0.5;
                }
            }
            > .title {
                margin-left: 15px;
                font-size: 18px;
                color: $blue-500;
            }
            > .payment-method-type {
                font-size: 14px;
                color: $blue-500;
            }
            > .payment-method-type-icon {
                padding-right: 1em;
                > md-icon {
                    color: $blue-500;
                }
            }
        }
        .md-errors-spacer {
            display: none;
        }
        .payment-details-color-preview {
            width: 16px;
            height: 16px;
            border-radius: 100%;
        }
    }

    .fidelity-card {
        .fidelity-images {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            .fidelity-image-container {
                position: relative;
                margin: 16px 32px 16px 0;
                .fidelity-remove-image {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    border-radius: 100%;
                    background: white;
                }
            }
        }
    }

    .tip_predefined_values {
        .md-chips-messages {
            color: #dd2c00;
            position: relative;
            order: 4;
            overflow: hidden;
            & [ng-message] {
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                margin-top: 0;
                padding-top: 5px;
            }
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:109");