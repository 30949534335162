@use "sass:meta" as ---2rhhjtudcn6;.eatsready-payments {
    height: 80%;
    min-height: 300px;
    @media screen and (max-width: 639px) {
        max-width: 100vw;
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
    }
    .disabled {
        opacity: 0.25;
    }
    .eatsready-payments-payment {
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: row;
        align-items: center;
        .eatsready-payments-avatar {
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }
        .eatsready-payments-amount {
            font-weight: 500;
        }
    }
    .eatsready-loader {
        &#load-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        #loader {
            display: block;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: scale(4, 4);
        }

        .ball,
        .colorRing,
        .ringTwo,
        .ringThree,
        .ringFour {
            top: 50%;
            left: 50%;
            display:block;
            border-radius: 50%;
            position:absolute;
        }

        .ball {
            width: 10px;
            height: 10px;
            margin: -5px 0 0 -5px;
            background: rgb(249, 76, 67);
            animation: 2000ms pulseInPrimary 1500ms cubic-bezier(0.4, 0.0, 0.2, 1)  infinite;
            -webkit-animation: 2000ms pulseInPrimary 1500ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }

        .colorRing {
            width: 16px;
            height: 16px;
            margin: -9px 0 0 -9px;
            border: 1px solid rgb(249, 76, 67); ;
            animation: 2000ms pulseIn cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            -webkit-animation: 2000ms pulseIn 100ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }

        @keyframes pulseIn {
            0% {
                opacity: 0;
                transform:scale(0.2, 0.2);
            }
            50% {
                opacity: 1;
                transform:scale(1, 1);
            }
            100% {
                opacity: 0;
                transform:scale(0.2, 0.2);
            }
        }

        @-webkit-keyframes pulseIn {
            0% {
                opacity: 0;
                -webkit-transform:scale(0.2, 0.2);
            }
            50% {
                opacity: 1;
                -webkit-transform:scale(1, 1);
            }
            100%{
                opacity: 0;
                -webkit-transform:scale(0.2, 0.2);
            }
        }

        @keyframes pulseInPrimary{
            0% {
                opacity: 1;
                transform:scale(0.2, 0.2);
            }
            50% {
                opacity: 1;
                transform:scale(1.4, 1.4);
            }
            100% {
                opacity: 1;
                transform:scale(0.1, 0.1);
            }
        }

        @-webkit-keyframes pulseInPrimary{
            0%{
                opacity: 0;
                -webkit-transform:scale(0.1, 0.1);
            }
            50%{
                opacity: 1;
                -webkit-transform:scale(1, 1);
            }
            100%{
                opacity: 0;
                -webkit-transform:scale(0.2, 0.2);
            }
        }

        .ringTwo{
            width: 40px;
            height: 40px;
            background: rgba(250, 250, 250, 0.03);
            border: 1px solid rgb(249, 76, 67);
            margin: -21px 0 0 -21px;
            animation: 2000ms pulseIn 500ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            -webkit-animation: 2000ms pulseIn 500ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite
        }

        @keyframes pulseInSecondary {
            0%{
                opacity: 0;
                transform:scale(0.2, 0.2);
            }
            50% {
                opacity: 1;
                transform:scale(1, 1);
            }
            100% {
                opacity: 0;
                transform:scale(0.2, 0.2);
            }
        }

        @-webkit-keyframes pulseInSecondary {
            0% {
                opacity: 0;
                -webkit-transform:scale(0.2, 0.2);
            }
            50% {
                opacity: 1;
                -webkit-transform:scale(1, 1);
            }
            100%{
                opacity: 0;
                -webkit-transform:scale(0.2, 0.2);
            }
        }

        .ringThree {
            width: 48px;
            height: 48px;
            border: 1px solid rgba(250, 250, 250, 0.03);
            margin: -25px 0 0 -25px;
            animation: 2000ms pulseInSecondary 550ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            -webkit-animation: 2000ms pulseInSecondary 550ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }

        .ringFour {
            width: 56px;
            height: 56px;
            border: 1px solid rgba(250, 250, 250, 0.03);
            margin: -29px 0 0 -29px;
            animation: 2000ms pulseInSecondary 600ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            -webkit-animation: 2000ms pulseInSecondary 600ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }

        #label {
            position: relative;
            top: 66%;
            p {
                line-height: 1.2;
                font-family: 'Inconsolata', sans-serif;
                text-align: center;
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:69");