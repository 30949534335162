@use "sass:meta" as ---2rhhjtudcn6;.split-sale-modal-dialog {
    margin: 0 !important;
    min-width: 75% !important;
    max-width: 1080px;

    .split-sale-treat-amount-row {
        flex-shrink: 0;
        margin-bottom: 16px;
    }

    .split-sale-type-container {
        span {
            text-align: center;
            margin-top: 12px;
            max-width: 95%;
        }
        button {
            width: 95%;
        }
        img {
            width: 90%;
        }
        &.disabled {
            img {
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
            }
        }
    }
    
    @media (max-width: 599px) {
        .split-sale-type-container {
            margin-bottom: 32px;
        }
    }

    .split-sale-treat-items-list {
        height: 100vh !important;
    }

    .split-sale-preview-people-total {
        margin: 24px 8px;
        color: $blue;
        span {
            margin-left: 8px;
        }
        md-icon {
            color: $blue !important;
        }
    }

    .split-sale-item{
        &.sale-item-child {
            padding-left: 32px;
        }
        padding: 16px 0;
        width: 100%;
        touch-action: pan-y !important; //Necessary for list scroll
        .split-sale-badge{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $text-disabled;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $text-title;
            margin-right: 8px;
            &.active{
                background-color: $blue;
                color: white;
            }
        }
        @media (min-width: 600px) {
            .split-sale-price{
                text-align: right;
                padding-right: 8px;
            }
        }
        .split-sale-notes {
            color: $text;
            font-size: .8em;
            white-space: pre;
        }
    }

    .split-sale-right{
        padding-left: 32px;
        .split-sale-right-header{
            color: $blue;
        }
    }

    .split-sale-placeholder{
        margin-top: 30%;
        img{
            width:50%;
            max-width: 100%;
        }
        .split-sale-placeholder-text{
            margin-top: 32px;
            color: $blue;
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:67");