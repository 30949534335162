@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $text: mat.get-color-from-palette($foreground, 'text');
    $is-dark-theme: map.get($color-config, 'dark-theme');

    $primary-color-contrast: mat.get-color-from-palette($primary-palette, '500-contrast');

    app-new-material-sidenav,
    new-sidenav {

        span.frame-sidenav.color {
            color: $text !important;
        }

        mat-sidenav.frame-sidenav, mat-toolbar.frame-toolbar {
            color: $text !important;
            background-color: mat.get-color-from-palette($primary-palette) !important;
            border-color: mat.get-color-from-palette($primary-palette) !important;
        }

        .mat-button-toggle.frame-button-toggle {
            background-color: #ffffff; //mat.get-color-from-palette($primary-palette);
            color: $text !important;
        }

        button[mat-icon-button].frame-button,
        button[mat-button].frame-button
        {
            color: mat.get-color-from-palette($accent-palette, '50-contrast');
        }

        button[mat-icon-button].frame-button.active {
            color: $primary-color-contrast;
        }

        //button[mat-icon-button].frame-button:hover,
        //button[mat-button].frame-button:hover,
        //button[mat-menu-item].frame-button:hover
        //{
        //    color: black;
        //}
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:374");