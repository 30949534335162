@use "sass:meta" as ---2rhhjtudcn6;.invoice-passive-payments {
    width: 700px;
    md-dialog-content {
        padding: 0;
        ng-form {
            md-input-container {
                .md-errors-spacer {
                    display: none;
                }
            }
            .md-icon-button {
                margin: 0;
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:104");