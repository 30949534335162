@use "sass:meta" as ---2rhhjtudcn6;.kiosk-settings {
    md-input-container {
        padding: 10px;
    }

    .kiosk-checkbox{
        padding: 10px;
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:119");