@use "sass:meta" as ---2rhhjtudcn6;.refund-items-selector {
    min-width: 50%;
    .refund-items-selector-container {
        height: 100vh;
        .refund-items-selector-row {
            width: 100%;
            padding: 12px 0;
            md-select {
                margin: 0 12px 0 0;
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:105");