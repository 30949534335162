@use "sass:meta" as ---2rhhjtudcn6;.number-input-dialog {
    .number-input-dialog-message {
        font-size: 1.5em;
        margin-bottom: 8px;
        text-align: center;
    }
    .number-input-dialog-display {
        border: 3px solid black;
        font-size: 4em;
        margin-bottom: 8px;
        line-height: 100px;
        min-height: 100px;
        text-align: center;
    }
    .number-input-dialog-confirm {
        background: #4d4d4d;
        color: white;
        font-size: 3em;
        line-height: 100px;
        min-height: 100px;
        text-align: center;
    }
    keypad-pin {
        min-height: 400px !important;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:42");