$default-horizontal-margin: 0;
@import '~angular-material/angular-material.scss';

$base-url :"/";

//Generic text colour based from Material Color Palette (http://www.google.com/design/spec/style/color.html#color-color-palette)
$text-title: #424242; //grey 800
$text: #757575; //grey 600
$text-light: #BDBDBD;
$text-disabled: #E0E0E0; //grey 300

$busy: #D50000;
$available: #009688;
$checkout: #F9A825;
$offline-icon:#FFCC80; //Orange 200
$bkg-grey-dark: #f5f5f5; //Grey 100
$subheader: #E0E0E0;
$subheader-light: #F5F5F5;

//primary template colours
$primary-tilby: #e3f2fd;
$blue: #1a73e8;
$blue-100: #BBDEFB;
$blue-200: #90CAF9;
$blue-300: #64B5F6;
$blue-500: #2196f3;
$teal-500: #009688;

//stock
$available-stock-color: #8bc34a;
$unavailable-stock-color: #ff5252;
$alert-stock-color:  #ffc107;

md-toolbar:not(.md-satispayTheme-theme):not(.md-eatsreadyTheme-theme):not(.md-spiaggeItTheme-theme), md-toolbar.md-default-theme {
	background-color: $primary-tilby !important;
}

button.md-primary.md-raised:not(.md-satispayTheme-theme):not(.md-eatsreadyTheme-theme):not(.md-spiaggeItTheme-theme):not([disabled]),
button.md-fab:not(.md-raised):not(.md-satispayTheme-theme):not(.md-eatsreadyTheme-theme):not(.md-spiaggeItTheme-theme):not([disabled]) {
	color: white;
	md-icon {
		color: white;
	}
}

md-tabs.md-default-theme.md-primary>md-tabs-wrapper, md-tabs.md-primary>md-tabs-wrapper {
	background-color: $primary-tilby !important;
	md-tab-item {
		color: black !important;
	}
}

md-tab-item {
	min-width: initial !important;
	padding: 12px 24px !important;
}

md-content {
	background-color: white !important;
}

.til-avatar-icon {
	font-size: 50px !important;
    color: #689f38 !important;
    height: 50px !important;
    width: 50px !important;
}

.icon-chips {
	md-chips-wrap {
		box-shadow: none;
		md-chip {
			padding: 0 6px 0 6px !important;
			em {
				padding: 0 12px 0 4px;
			}
		}
	}
}

.pricelist-number {
	position: relative;
	span {
		bottom: 8px;
		color: $primary-tilby;
		left: 8px;
		height: 24px;
		position: absolute;
		width: 24px;
	}
}

.md-subheader-content {
	md-checkbox {
		position: relative;
		min-height: 18px;
		.md-container {
			top: 8px;
			left: 0;
			right: 6px;
			margin: 0;
		}
	}
}

.til-fieldset {
	margin: 16px 8px;
	border-style: solid;
	border-width: 1px;
}

/* Component SCSS */
@import "modules/core/core.scss";
@import "modules/application/application.scss";
@import "modules/bookings/bookings.scss";
@import "modules/printers/printers.scss";
@import "modules/cashregister/cashregister.scss";
@import "modules/items/items.scss";
@import "modules/history/history.scss";
@import "modules/digital-payments/digital-payments.scss";
@import "modules/settings/settings.scss";
@import "modules/stock/stock.scss";
@import "modules/fidelity/fidelity.scss";
@import "modules/kiosk/kiosk.scss";
@import "modules/promotions/promotions.scss";
/* Add Component SCSS Above */

//Add ui-cropper css
@import "~ui-cropper/compile/unminified/ui-cropper.css";
// @import "~shepherd.js/dist/css/shepherd.css";
@import "./shepherd-custom-theme.css";

.max-height {
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.bold {
	font-weight: bold;
}

.back-loader{
  position: absolute;
  height: 100vh !important;
  width: 100vw;
  background: white;
  z-index: 200;
}

.section-loader{
  position: unset;
  height: 200px !important;
  width: 100%;
  background: none;
  z-index: 200;
}

body {
    overflow: hidden !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

	&.notch-fixes {
		position: fixed;
		height: calc((100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)));
		width: calc((100vw - env(safe-area-inset-left) - env(safe-area-inset-right)));
		margin-top: env(safe-area-inset-top);
		margin-bottom: env(safe-area-inset-bottom);
		margin-right: env(safe-area-inset-right);
		margin-left: env(safe-area-inset-left);

		.md-dialog-container {
			top: 0px !important;
		}

		.md-sidenav-left, .md-sidenav-right, .modal-dialog {
			padding-bottom: calc(env(safe-area-inset-bottom) + env(safe-area-inset-top));
		}

		md-toast {
			margin-bottom: calc(env(safe-area-inset-bottom) + env(safe-area-inset-top));
		}
	}
}

*:focus{
    outline: none;
}

.layout-padding-no-inherit-8 {
  padding: 8px;
}

.layout-padding-no-inherit-16 {
    padding: 16px;
}

.md-select-form {
    margin-bottom: 24px;
}

.opacity-wait {
	opacity: 0.6;
}

.md-datepicker-flex {
	&._md-datepicker-floating-label {
		&._md-datepicker-has-calendar-icon {
			> label {
				&:not(.md-no-float) {
					&:not(.md-container-ignore) {
						left  : 34px;
						right : 0;
					}
				}
			}
		}
	}

	md-datepicker {
		display : inline-block;
		padding : 0;
		width   : 100%;

		.md-datepicker-button {
			margin-left : -12px;
		}

		.md-datepicker-input-container {
			display : flex;

			.md-datepicker-input {
				max-width : 100%;
				min-width : 100%;
			}

			.md-datepicker-triangle-button {
				right : 8px;
			}
		}
	}

	.md-input-messages-animation {
		.md-input-message-animation {
			margin-left : 34px;
		}
	}
}

@media (max-width: 959px) {
	md-toast {
	  height: 92px;
	  .md-toast-content {
		  max-height: 92px;
		  height: 92px;
	  }
	}
}

//Make choices on disabled checkboxes visible
md-radio-group {
	md-radio-button {
		margin-right: 16px;
	}
	&[disabled] {
		md-radio-button {
			&.md-checked {
				.md-on {
					background-color: darkgray;
				}
			}
		}
	}
}

md-checkbox, md-radio-group {
	.md-label {
		line-height: 1.4;
	}
}

.rotating-icon-clockwise {
	animation-name: rotating-icon-clockwise;
	animation-duration: 3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;
	display: inline-block;
}

@keyframes rotating-icon-clockwise {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.rotating-icon-counterclockwise {
	animation-name: rotating-icon-counterclockwise;
	animation-duration: 3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;
	display: inline-block;
}

@keyframes rotating-icon-counterclockwise {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}


.scl-table {
	border-spacing: 0;
	tr {
		height: 48px;
		th, td {
			padding: 0 8px;
		}
		th {
			text-align: left;
			font-weight: 500;
			color: rgba(0,0,0,.54);
		}
		th, &:not(:last-child) > td{
			border-bottom: 1px solid lightgrey;
			border-spacing: 0;
		}
	}
}