@use "sass:meta" as ---2rhhjtudcn6;.historymodule-sidenav {
    width: 426px;
    max-width: 100vw;
    height: 100vh;
    display: flex;
}

.historymodule-pay-title {
    margin-left: 16px;
    padding-top: 16px;
}

.historymodule-items-content {
    overflow-x: hidden !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.historymodule-items-list {
    padding-top: 1px;
    transition: background-color 0.15s linear;
    margin: 1px 0;
    .historymodule-item-row {
        min-height: 72px;
        padding: 16px;
        flex-shrink: 0;
        .historymodule-item-badge {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $text-disabled;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $text-title;
            margin-right: 16px;
            &.active {
                background-color: $blue;
                color: white;
            }
            &.negative {
                background-color: $busy;
                color: white;
            }
        }
        .historymodule-item-operator {
            font-size: .8em;
        }
        .historymodule-item-gift,
        .historymodule-item-note {
            color: $text;
            font-size: .8em;
            white-space: pre;
        }
        .historymodule-item-refund {
            color: $busy;
            font-size: .8em;
        }
        .historymodule-item-change-price {
            margin-bottom: 12px;
            &:last-child{
                margin-bottom: 0;
            }
            span{
                color: $blue;
            }
        }
        .historymodule-item-name {
            padding-top: 10px;
        }
    }
}

.historymodule-item-change-price-solo {
    padding: 16px 16px 0 16px;
    margin-bottom: 24px;
    span {
        color: $text-title;
    }
}

.historymodule-payments-box {
    background: $bkg-grey-dark;
    .historymodule-payment-row {
    padding: 16px;
    //border-bottom: 1px solid lightgrey;
        .historymodule-payment-amount {
            color: $text-title;
        }
    }
}

.historymodule-bottom-btn {
    height: 82px;
    min-height: 82px;
    width: 100%;
    display: flex;
    .historymodule-blue {
        flex: 1;
        background: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }
    .historymodule-light-green {
        flex: 1;
        background: #81C784;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }
    .historymodule-green {
        flex: 1;
        background: #4CAF50;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
    }
}

.historymodule-items-title-header {
    margin-top: 0px;
    span.historymodule-name {
        font-size: .9em;
    }
    span.historymodule-items-header-time {
        font-size: .5em;
        margin-left: 10px;
    }
    div.historymodule-info {
        font-size: .6em;
    }
}

.history-module-header-customer-name {
    font-size: .9em;
}

.historymodule-header-total {
    text-transform: uppercase;
    text-align: right;
    font-size: .9em;
    font-weight: 500;
}

.toolbar-historymodule-items {
    height: 112px;
    min-height: 112px;
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:91");