@use "sass:meta" as ---2rhhjtudcn6;.icon-list-dialog-container {
    flex: 1;
    padding: 16px 16px 16px 0;
    display: flex;
    flex-direction: column;
    .icon-list-dialog-item {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon-list-dialog-image {
            width: 50px;
            img{
                width: 40px;
                border-radius: 50%;
                height: 40px;
                background: $blue;
            }
            div.icon-list-dialog-placeholder {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: $text;
            }
        }
        .icon.list-dialog-name {
            flex:1;
            margin-left: 16px;
            &.active{
                color: $blue;
            }
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:40");