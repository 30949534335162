@use "sass:meta" as ---2rhhjtudcn6;.kiosk-payment-selection {
    .kiosk-payment-row {
        position: relative;
        overflow: hidden;
        .kiosk-wrapper-btn-help {
            position: absolute;
            top: 0;
            right: 0;
            margin: 16px 16px 0 0;
        }
        video.background {
            width: 100%;
            object-fit: cover;
        }
        img.background {
            height: 100%;
            width: 100%;
        }
        .kiosk-wrapper-btn {
            position: absolute;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:145");