@use "sass:meta" as ---2rhhjtudcn6;.spiagge-it-payments {
    height: 80%;
    min-height: 300px;
    @media screen and (max-width: 639px) {
        max-width: 100vw;
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
    }
    .spiagge-it-payments-payment {
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: row;
        align-items: center;
        .spiagge-it-payments-icon {
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }
        .spiagge-it-payments-divider {
            margin: 0 8px;
        }
        &.selected {
            background-color: #FFECB3;
        }
    }
    .spiagge-it-payments-search {
        ::placeholder {
            color: black;
            opacity: 0.6;
        }
        input {
            border: 0;
            border-bottom: 1px solid rgba(255, 255,255, .6);
            background-color: transparent;
            color: black;
        }
    }
    .spiagge-it-payments-room {
        font-weight: bold;
        font-size: 36px;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:74");