@use "sass:meta" as ---2rhhjtudcn6;showcase-photogrid {
    .showcase-photogrid-tile-thumbnail {
        :active {
            opacity: 0.75;
        }
        img {
            object-fit: cover;
        }
        md-grid-tile-footer {
            background: rgba(245, 245, 245, 0.85) !important;
            height: 56px !important;
            figcaption {
                position: relative;
                height: 100%;
                width: 100%;
                padding: 0 !important;
                display: flex;
                flex-direction: column;
                .tile-footer-row {
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    &.item-name {
                        color: $text-title;
                        margin-bottom: 4px;
                        font-weight: 500;
                        font-size: 1em;
                        padding: 6px 8px 0 8px;
                    }
                    &.item-price {
                        color: $blue;
                        font-weight: bold;
                        text-align: right;
                        padding: 0 8px;
                    }
                }
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:18");