@use "sass:meta" as ---2rhhjtudcn6;keypad-pin {
    .keypad-row {
        border-bottom: 1px solid white;
    }
    .key-number {
        border-right: 1px solid white;
        color: white;
        background: darken(grey, 20%);
        justify-content: center;
        align-items: center;
        display: flex;
        &.delete-button{
            background: grey;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:17");