@use "sass:meta" as ---2rhhjtudcn6;.components-search-bar {
    border-bottom: 1px solid lightgray;
    flex-shrink: 0;
    .components-search-icon {
        margin: 0 16px;
    }
    .components-search-input {
        margin: 8px 0;
        input {
            border: none !important;
        }
        .md-errors-spacer {
            display: none;
        }
    }
    .components-search-cancel {
        margin-right: 8px;
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:86");