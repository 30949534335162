@use "sass:meta" as ---2rhhjtudcn6;.stock-management-search-bar{
    border-bottom: 1px solid lightgray;
    height: 64px;
    width: 100%;
    color: black;
    padding: 0 16px;
    background: white;
    z-index: 2;
    display: flex;
    box-sizing: border-box;
    .sale-search-input{
        margin-top: 38px;
        input{
            border:none !important;
        }
    }
}

.stock-row{
    height: 56px;
    min-height: 56px;
    padding: 0 16px;
    border-bottom: 1px solid lightgrey;
}

.stock-round-color{
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
}

.stock-round-color-subheader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.stock-elem-light{
    color: $text;
}

.stock-elem-name {
    max-height: 60px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.stock-management-content {
    overflow: hidden;
    z-index: 1;
}

.stock-search-placeholder{
    text-align: center;
    img{
        width: 300px;
    }
    div{
        color:$blue;
    }
}

.stock-subheader-management{
    .md-subheader-inner{
        padding: 8px 16px !important;
    }
}

.stock-subheader-icon-not-available {
    margin: 3px 12px;
}

.stock-unit{
    color: $text;
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:124");