@use "sass:meta" as ---2rhhjtudcn6;.item-selector-dialog {
    min-height: 80%;
    height: 80%;
}

.item-selector-modal-header {
    background-color: $primary-tilby;
}

.item-selector-searchbox {
    background-color: inherit;
    border: none;
}

.item-selector-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 60%;
    .item-selector-placeholder-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img {
            max-height: 96px;
        }
        .item-selector-placeholder-message {
            color: $blue;
        }
    }
}

.item-selector-dialog-content {
    padding: 32px 0 32px 0;
    overflow-y: auto;
    flex: 1;
}

.item-selector-pagination {
    min-height: 56px !important;
    max-height: 56px !important;
}

.item-selector-dialog-padding {
    padding: 0;
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:24");