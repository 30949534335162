@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;

md-sidenav, //for downgrade Menu
app-new-material-sidenav,
new-sidenav{

    app-sidenav-menu, new-sidenav-menu-component {
        &>[cdkscrollable]{
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .mat-sub-button-sidebar mat-icon,
        .mat-button-sidebar mat-icon
        {
            overflow: visible;
        }
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:397");