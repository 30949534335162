@use "sass:meta" as ---2rhhjtudcn6;showcase-list {
    overflow-y: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .showcase-list-items-content {
        padding: 0 0 8px 0 !important;
        width: 100%;
        height: 100vh;

        &.showcase-list-item-compact {
            .showcase-list-item {
                height: 44px;
                min-height: 44px;
                .showcase-list-item-image {
                    max-width: 44px;
                    width: 44px;
                    height: 44px;
                    max-height: 44px;
                }
                .showcase-list-item-badge {
                    max-width: 40px;
                    width: 40px;
                    height: 40px;
                    max-height: 40px;
                }
                .showcase-list-item-name {
                    max-height: 44px;
                }
                .showcase-list-item-badge-exit {
                    height: 20px;
                    line-height: 20px;
                }
            }
        }
    }
}
;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:20");