@use "sass:meta" as ---2rhhjtudcn6;.item-category {
    .item-details-color-preview {
        width: 16px;
        height: 16px;
        border-radius: 100%;
    }

    .fieldset-items-has-button {
		legend {
			padding: 0;
		}
		.fieldset-items-button {
			margin: 0 !important;
			padding: 0 !important;
			border: 1px solid lightgray;
		}
	}
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:82");