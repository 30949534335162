@use "sass:meta" as ---2rhhjtudcn6;md-content.promotions-showcase-content {
    overflow: hidden !important;
    z-index: 1;
}

.promotions-showcase-subheader {
    background-color: $subheader-light !important;
    min-height: 48px;
}

.promotionsmodule-content-list {
    padding: 0 0 8px 0 !important;
    width: 100%;
    height: 100vh;
    overflow: auto;
    justify-content: center;
    .promotionsmodule-list-promotion {
        border-bottom: 1px solid lightgrey;
        height: 80px;
        min-height: 80px;
    }
}

.promotionsmodule-showcase-no-promotions-placeholder {
    display: flex;
    justify-content: center;
    .promotionsmodule-showcase-no-promotions-placeholder-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 30%;
        }
        .promotionsmodule-showcase-no-promotions-placeholder-message{
            color: $blue;
        }
    }
}

.promotion-round-color{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:150");