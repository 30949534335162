@use "sass:meta" as ---2rhhjtudcn6;@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $text: mat.get-color-from-palette($foreground, 'text');
    $is-dark-theme: map.get($color-config, 'dark-theme');
    $type: mat.get-theme-type($theme);

    $primary-color: mat.get-color-from-palette($primary-palette);
    $primary-color-select: if($type == dark, mat.get-color-from-palette($primary-palette, 200), mat.get-color-from-palette($primary-palette, 700));
    $primary-color-contrast: mat.get-color-from-palette($primary-palette, '500-contrast');
    $background-color: mat.get-color-from-palette($background,'background');
    $dialog-background-color: mat.get-color-from-palette($background,'dialog');

    //@debug "$color-config frame" $color-config;
    .tilby-frame-primary-color{color:$primary-color !important;}
    .tilby-frame-background-primary-color{background:$primary-color !important; color: $primary-color-contrast !important;}

    //NEW-MATERIAL-SIDENAV(BODY DI TILBY)
    md-sidenav, //for downgrade Menu
    app-new-material-sidenav,
    new-sidenav {
        mat-sidenav-content {
            background-color: $primary-color;
        }
    }

    dashboard,
    app-cashregister,
    cashregister-new-component{
        .tilby-frame-primary{
            background: $primary-color;
            color: $primary-color-contrast;
        }
    }

    app-sidenav-menu, new-sidenav-menu-component {
        app-nav-list{
            .nav-button-active{
                background: $primary-color;
            }
        }
        button[mat-button]{
            border-radius: 1.5rem;
        }
    }

    //CASHREGISTER
    app-cashregister,
    cashregister-new-component
    {
        :has(tilby-shop-cart) .tilby-frame-primary{
            background: $primary-color;
            color: $primary-color-contrast;
        }

        app-tilby-draggable-bottom-sheet{
            .bottom-sheet-bg{
                background: $dialog-background-color;
            }
        }

        tilby-shop-cart {
            //mat-list-item.selected-item-group,
            mat-list-item.selected-item {
                background-color: $primary-color-select;
            }
        }

        app-cashregister-mobile-view,
        app-cashregister-payments-mobile-view{
            .as-split-gutter{
                background-color: $primary-color !important;
                color: $primary-color-contrast !important;
            }
        }
    }

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

;@include ---2rhhjtudcn6.load-css("sass-embedded-legacy-load-done:382");